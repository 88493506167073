.accordion__button {
  position: relative;
  width: parent;
  cursor: pointer;
}

.accordion__button:before {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -2px;
  bottom: 0;
  right: 0;
  margin: auto;
  content: '';
  height: 10px;
  width: 10px;
  border-bottom: 3px solid;
  border-right: 3px solid currentColor;
  transform: rotate(45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(-132deg);
}

.accordion-area {
  min-height: calc(100vh - 350px);
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
